import React from "react";
import Main from "layouts/Main";

import HeroHeader from "components/HeroHeader";

import PricingSection from "components/PricingSection";
import { pricingData } from "data/pricing";

import FeatureSection from "components/FeatureSection";

import FaqAccordion from "components/Faq";
import { faqData } from "data/faq";

import UserTestimonial from "components/UserTestimonial";
import { testimonials } from "data/testimonials";
import { featureSectionData } from "data/featureSection";
import { Box } from "@mui/material";

const Pricing = () => {
  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main>
        <HeroHeader
          title="We've Got A Plan That's Perfect For You"
          description="Choose the plan that grows with you. Streamlined Salesforce integration, no hidden fees, no complexity. Start enhancing your app's connectivity today."
          buttonTitle="Join The Waitlist"
          buttonLink="/joinWaitlist"
          highlightText={{
            title: {
              text: "For You",
            },
          }}
        />
        <PricingSection pricingOptions={pricingData} />
        <FeatureSection radioSelection="Consulting Firms" contentData={featureSectionData} />

        <FaqAccordion questions={faqData} />

        <UserTestimonial
          title="User Feedback"
          subtitle="Hear directly from our pioneering users who have experienced firsthand the transformative potential of Appnigma AI."
          testimonials={testimonials}
        />
      </Main>
    </Box>
  );
};

export default Pricing;
